import useEmblaCarousel, {
	type UseEmblaCarouselType,
} from 'embla-carousel-react'
import * as React from 'react'

import { Button } from '#app/components/ui/button'
import { Icon } from '#app/components/ui/icon'
import { cn } from '#app/utils/misc'

type CarouselApi = UseEmblaCarouselType[1]
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = UseCarouselParameters[1]

type CarouselProps = {
	opts?: CarouselOptions
	plugins?: CarouselPlugin
	orientation?: 'horizontal' | 'vertical'
	setApi?: (api: CarouselApi) => void
	disableOrientation?: boolean
}

type CarouselContextProps = {
	carouselRef: ReturnType<typeof useEmblaCarousel>[0]
	api: ReturnType<typeof useEmblaCarousel>[1]
	scrollPrev: () => void
	scrollNext: () => void
	canScrollPrev: boolean
	canScrollNext: boolean
} & CarouselProps

const CarouselContext = React.createContext<CarouselContextProps | null>(null)

function useCarousel() {
	const context = React.useContext(CarouselContext)

	if (!context) {
		throw new Error('useCarousel must be used within a <Carousel />')
	}

	return context
}

const Carousel = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
	(
		{
			orientation = 'horizontal',
			opts,
			setApi,
			plugins,
			className,
			children,
			disableOrientation,
			...props
		},
		ref,
	) => {
		const [carouselRef, api] = useEmblaCarousel(
			{
				...opts,
				axis: orientation === 'horizontal' ? 'x' : 'y',
			},
			plugins,
		)
		const [canScrollPrev, setCanScrollPrev] = React.useState(false)
		const [canScrollNext, setCanScrollNext] = React.useState(false)

		const onSelect = React.useCallback((api: CarouselApi) => {
			if (!api) {
				return
			}

			setCanScrollPrev(api.canScrollPrev())
			setCanScrollNext(api.canScrollNext())
		}, [])

		const scrollPrev = React.useCallback(() => {
			if (!api) return

			const currentIndex = api.selectedScrollSnap()
			let prevIndex = currentIndex - 5
			prevIndex = Math.max(prevIndex, 0)

			api.scrollTo(prevIndex)
		}, [api])

		const scrollNext = React.useCallback(() => {
			if (!api) return

			const currentIndex = api.selectedScrollSnap()
			let nextIndex = currentIndex + 5
			nextIndex = Math.min(nextIndex, api.scrollSnapList().length - 1)

			api.scrollTo(nextIndex)
		}, [api])

		const handleKeyDown = React.useCallback(
			(event: React.KeyboardEvent<HTMLDivElement>) => {
				if (event.key === 'ArrowLeft') {
					event.preventDefault()
					scrollPrev()
				} else if (event.key === 'ArrowRight') {
					event.preventDefault()
					scrollNext()
				}
			},
			[scrollPrev, scrollNext],
		)

		React.useEffect(() => {
			if (!api || !setApi) {
				return
			}

			setApi(api)
		}, [api, setApi])

		React.useEffect(() => {
			if (!api) {
				return
			}

			onSelect(api)
			api.on('reInit', onSelect)
			api.on('select', onSelect)

			return () => {
				api?.off('select', onSelect)
			}
		}, [api, onSelect])

		return (
			<CarouselContext.Provider
				value={{
					carouselRef,
					api: api,
					opts,
					orientation:
						orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
					scrollPrev,
					scrollNext,
					canScrollPrev,
					canScrollNext,
				}}
			>
				<div
					ref={ref}
					onKeyDownCapture={handleKeyDown}
					className={cn('relative', className)}
					role="region"
					aria-roledescription="carousel"
					{...props}
				>
					{children}
				</div>
			</CarouselContext.Provider>
		)
	},
)
Carousel.displayName = 'Carousel'

const CarouselProgress = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
	const [currentIndex, setCurrentIndex] = React.useState(0)
	const { api, scrollNext, canScrollNext, canScrollPrev, scrollPrev } =
		useCarousel()

	React.useEffect(() => {
		if (!api) return
		const onSelect = () => {
			setCurrentIndex(api.selectedScrollSnap())
		}

		api.on('select', onSelect)

		return () => {
			api.off('select', onSelect)
		}
	}, [api])

	if (!api) {
		return null
	}

	const amountOfSlides = api.scrollSnapList().length
	const translatePercentage = (currentIndex / (amountOfSlides - 1)) * 100

	if (!canScrollNext && !canScrollPrev) {
		return null
	}
	return (
		<div ref={ref} {...props} className="space-x2 flex flex-row items-center">
			{canScrollPrev && (
				<Icon
					name="chevron-right-arrow"
					className="m-auto rotate-180"
					onClick={scrollPrev}
				/>
			)}
			<div
				className="special-wrap relative h-2 rounded-full bg-beige-50"
				style={{
					width: '50px',
				}}
			>
				<div
					className="h-2 rounded-full bg-mint-green-80p transition-all"
					style={{
						width: `${translatePercentage === 0 ? 15 : translatePercentage}%`,
					}}
				/>
			</div>
			{canScrollNext && (
				<Icon
					name="chevron-right-arrow"
					className="m-auto"
					onClick={scrollNext}
				/>
			)}
		</div>
	)
})

CarouselProgress.displayName = 'CarouselProgress'

const CarouselContent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & { disableOrientation?: boolean }
>(({ className, disableOrientation, ...props }, ref) => {
	const { carouselRef, orientation } = useCarousel()

	return (
		<div ref={carouselRef} className="overflow-hidden">
			<div
				ref={ref}
				className={cn(
					'flex',
					orientation === 'vertical' && !disableOrientation
						? '-mt-4 flex-col'
						: '',
					orientation === 'horizontal' && !disableOrientation ? '-ml-4' : '',
					className,
				)}
				{...props}
			/>
		</div>
	)
})
CarouselContent.displayName = 'CarouselContent'

const CarouselItem = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLDivElement> & { disableOrientation?: boolean }
>(({ className, disableOrientation, ...props }, ref) => {
	const { orientation } = useCarousel()

	return (
		<div
			ref={ref}
			role="group"
			aria-roledescription="slide"
			className={cn(
				'min-w-0 shrink-0 grow-0 basis-full',
				!disableOrientation && (orientation === 'horizontal' ? 'pl-4' : 'pt-4'),
				className,
			)}
			{...props}
		/>
	)
})
CarouselItem.displayName = 'CarouselItem'

const CarouselPrevious = React.forwardRef<
	HTMLButtonElement,
	React.ComponentProps<typeof Button>
>(({ className, variant = 'secondary', size = 'icon', ...props }, ref) => {
	const { orientation, scrollPrev, canScrollPrev } = useCarousel()

	return (
		<Button
			ref={ref}
			variant={variant}
			size={size}
			className={cn(
				' group absolute z-10 rounded-full px-4 py-[4px]',
				orientation === 'horizontal'
					? 'top-1/2 hidden -translate-y-1/2 md:left-12 md:block'
					: '-top-12 left-1/2 -translate-x-1/2 rotate-90',
				canScrollPrev ? 'md:block' : 'md:hidden',
				className,
			)}
			disabled={!canScrollPrev}
			onClick={scrollPrev}
			aria-label="Previous slide"
			{...props}
		>
			<Icon
				className="text-body-md text-black group-hover:text-white group-focus:text-black "
				size="lg"
				name="left-arrow"
			/>
			<span className="sr-only">Previous slide</span>
		</Button>
	)
})
CarouselPrevious.displayName = 'CarouselPrevious'

const CarouselNext = React.forwardRef<
	HTMLButtonElement,
	React.ComponentProps<typeof Button>
>(({ className, variant = 'secondary', size = 'icon', ...props }, ref) => {
	const { orientation, scrollNext, canScrollNext } = useCarousel()

	return (
		<Button
			ref={ref}
			variant={variant}
			size={size}
			className={cn(
				'group absolute rounded-full px-4 py-[4px]',
				orientation === 'horizontal'
					? 'top-1/2 hidden -translate-y-1/2 md:right-12 md:block'
					: '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
				canScrollNext ? 'md:block' : 'md:hidden',
				className,
			)}
			disabled={!canScrollNext}
			onClick={scrollNext}
			aria-label="Next slide" // Adding an aria-label for better accessibility
			{...props}
		>
			<Icon
				className="text-body-md text-black group-hover:text-white group-focus:text-black"
				size="lg"
				name="right-arrow"
			/>
			<span className="sr-only">Next slide</span>
		</Button>
	)
})
CarouselNext.displayName = 'CarouselNext'

export {
	type CarouselApi,
	Carousel,
	CarouselProgress,
	CarouselContent,
	CarouselItem,
	CarouselPrevious,
	CarouselNext,
}
